import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ErrorPage from "./ErrorPage";
import Main from "./Main";
import Page from '../components/Page';
import styled from "styled-components";
import Footer from "../components/Footer";
import {getLatestRelease} from "../tracks";
import {parseDateOrdinal} from "../utils/ordinal";
import Track from "./Track";

const firebaseConfig = {
    apiKey: "AIzaSyDPcE9y6p7kMk6GiycctK44JCdOaacneAw",
    authDomain: "lounge-sprouts.firebaseapp.com",
    projectId: "lounge-sprouts",
    storageBucket: "lounge-sprouts.appspot.com",
    messagingSenderId: "997300162878",
    appId: "1:997300162878:web:9a8bee742aaa9f1768dc6f",
    measurementId: "G-2L454X3HYF"
};

const AppContainer = styled.div`
  text-align: center;
  background: linear-gradient(180deg, ${props => props.theme.color.brand.primary.darkest }, ${props => props.theme.color.brand.primary.medium });
  min-height: 100vh;
  min-width: 100vw;
  overflow-y: hidden;
  color: ${props => props.theme.color.gray.scale100};
  font-weight: ${props => props.theme.fontWeight.regular};
  display: grid;
  @supports (height: 100dvh) {
    height: 100dvh;
  } 
 @supports (height: 100dvw) {
        width: 100dvw;
 }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

function App() {

    const [, setApp] = useState(null);
    const [analytics, setAnalytics] = useState(null);

    useEffect(() => {

        const initializeFirebase = async () => {
            const isFirebaseSupported = await isSupported();
            if(!isFirebaseSupported) return;
            const currentApp = initializeApp(firebaseConfig);
            const currentAnalytics = getAnalytics(currentApp);
            setApp(currentApp);
            setAnalytics(currentAnalytics);
        };

        initializeFirebase().then(r => r);

        return () => {
            setApp(null);
            setAnalytics(null);
        };
    }, []);

    const defaultDescription = "Discover Lounge Sprouts, an instrumental music project blending elements of game soundtrack, alternative rock, and indietronica."

    const pageDescription =    document.querySelector('meta[name="description"]');

    const latestRelease = getLatestRelease();
    const isReleased = latestRelease.releaseDate <= new Date();
    const singleDescription = isReleased ?
        `New ${latestRelease.type} ${latestRelease.name} is out, listen now!`
        :
        `New ${latestRelease.type} ${latestRelease.name} out on ${parseDateOrdinal(latestRelease.releaseDate)}, pre-save now!`;

    pageDescription.setAttribute('content', `${defaultDescription} ${singleDescription}`);

    return (
        <AppContainer>
            <PageContainer>
                <link rel="dns-prefetch" href="https://www.google.com"/>
                <link rel="dns-prefetch" href="https://www.googletagmanager.com"/>
                <link rel="dns-prefetch" href="https://googleads.g.doubleclick"/>
                <link rel="dns-prefetch" href="https://firebase.googleapis.com"/>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Page title="Lounge Sprouts"><Main analytics={analytics}/></Page>} errorElement={<ErrorPage/>} />
                        <Route path="/seeds" element={<Page title={"Seeds - Lounge Sprouts"}><Track name="Seeds"  analytics={analytics}/></Page>} errorElement={<ErrorPage/>} />
                        <Route path="/bucolic-fields" element={<Page title={"Bucolic Fields - Lounge Sprouts"}><Track name="Bucolic Fields"  analytics={analytics}/></Page>} errorElement={<ErrorPage/>} />
                        <Route path="/tidal-whisper" element={<Page title={"Tidal Whisper - Lounge Sprouts"}><Track name="Tidal Whisper"  analytics={analytics}/></Page>} errorElement={<ErrorPage/>} />
                        <Route path="/park-bench" element={<Page title={"Park Bench - Lounge Sprouts"}><Track name="Park Bench" analytics={analytics}/></Page>} errorElement={<ErrorPage/>} />
                        <Route path="/is-this-the-end" element={<Page title={"Is This the End? - Lounge Sprouts"}><Track name="Is This the End?" analytics={analytics}/></Page>} errorElement={<ErrorPage/>} />
                        <Route path="/under-the-tree-shadows" element={<Page title={"Under the Tree Shadows - Lounge Sprouts"}><Track name="Under the Tree Shadows" analytics={analytics}/></Page>} errorElement={<ErrorPage/>} />
                        <Route path="/a-new-beginning" element={<Page title={"A New Beginning - Lounge Sprouts"}><Track name="A New Beginning" analytics={analytics}/></Page>} errorElement={<ErrorPage/>} />
                    </Routes>
                </BrowserRouter>

            </PageContainer>


            <Footer/>
        </AppContainer>

    );
}

export default App;
