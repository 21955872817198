const theme = {
  color: {
      brand: {
        primary: {
            darkest: "#1a1627",
            dark: "#342B4F",
            medium: "#4d4176",
            light: "#69579E",
            lightest: "#897BB7",
        },
          secondary: {
              darkest: "#B87700",
              dark: "#F59F00",
              medium: "#FFB833",
              light: "#FFCD70",
              lightest: "#FFE2AD",
          },
          tertiary: {
              darkest: "#155150",
              dark: "#22817F",
              medium: "#2CA6A4",
              light: "#4ED0CE",
              lightest: "#7EDDDB",
          }
      },
      gray: {
          scale800: "#141414",
          scale700: "#292929",
          scale600: "#525252",
          scale500: "#7A7A7A",
          scale400: "#A3A3A3",
          scale300: "#C2C2C2",
          scale200: "#E0E0E0",
          scale175: "#EBEBEB",
          scale125: "#F5F5F5",
          scale100: "#FAFAFA",
      },
      highlight: {

      },
  },
    filter: {
        white: "invert(100%) sepia(0) saturate(0) hue-rotate(58deg) brightness(106%) contrast(96%)",
    },
  fontFamily: {
      brand: "Bebas Neue",
      system: "Montserrat"
  },
    fontWeight: {
        light: 200,
        regular: 300,
        medium: 400,
        bold: 500,
        extraBold: 600,
    },
    fontSize: {
      "3xs": "",
        "2xs": "",
        "xs": "",
        "sm": "",
        "md": "",
        "lg": "",
        "xl": "",
        "2xl": "",
        "3xl": "",
    }
};

export default theme;