import facebook from "../assets/icons/social/facebook.svg";
import twitter from "../assets/icons/social/twitter.svg";
import instagram from "../assets/icons/social/instagram.svg";
import spotify from "../assets/icons/social/spotify.svg";
import youtube from "../assets/icons/social/youtube.svg";
import soundcloud from "../assets/icons/social/soundcloud.svg";
import tiktok from "../assets/icons/social/tiktok.svg";
import {isSupported, logEvent} from "firebase/analytics";
import styled from "styled-components";
import AppAvatar from "./AppAvatar";

const socialAddresses = [
    {name: "facebook", href: "https://www.facebook.com/loungesprouts", icon: facebook},
    {name: "twitter", href: "https://twitter.com/loungesprouts", icon: twitter},
    {name: "instagram", href: "https://instagram.com/loungesprouts", icon: instagram},
    {name: "spotify", href: "https://open.spotify.com/artist/1YuBMji2c03FCmFeuj7tAo?si=OUPxCRDVRwi42CGhZl4vUg", icon: spotify},
    {name: "youtube", href: "https://www.youtube.com/@loungesprouts", icon: youtube},
    {name: "soundcloud", href: "https://soundcloud.com/loungesprouts", icon: soundcloud},
    {name: "tiktok", href: "https://www.tiktok.com/@loungesprouts", icon: tiktok},
];


export const AppTitle = styled.h1`
  font-weight: unset;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  margin-top: 2vh;
  font-size: calc(60px + 2vmin);
  font-family: ${props => props.theme.fontFamily.brand}, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
`;

const SocialArea = styled.span`
  display: flex;
  margin-top: 0px;
  padding-top: 0px;
`;

const SocialLink = styled.a`
  text-decoration: underline;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 0px;
  padding-top: 0px;
  display: flex;
  &:hover {
    transform: scale(1.1);
  }
`;

const SocialIcon = styled.img`
  margin-top: 8px;
  filter: ${props => props.theme.filter.white};
  width: 32px;
  height: auto;
  pointer-events: none;
  margin-top: 0px;
  padding-top: 0px;
`;

function Header(props) {

    const {analytics} = props;
    const logClickOnSocial = (name) => {
        try{
            if(!analytics) return;
            isSupported().then(() => {
                logEvent(analytics, 'social_link_clicked',
                    {name });
            });
        }catch(error){
            console.error(error);
        }

    };

   return (
       <>
           <AppAvatar/>
           <AppTitle>Lounge Sprouts</AppTitle>
           <SocialArea property="social-area" >
                    {socialAddresses.map(social => (
                        <SocialLink key={social.name} property={social.name} target="_blank" rel="noreferrer noopener" aria-label={social.name} href={social.href} onClick={() => {
                            logClickOnSocial(social.name);
                        }}>
                            <SocialIcon src={social.icon} width={48} height={32} alt="workdmark" />
                        </SocialLink>
                    ))}
           </SocialArea>
       </>

   );
}

export default Header;