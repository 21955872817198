import Highlight from "../components/Highlight";
import {highlightTracks} from "../tracks";

function Track(props) {
    const {name, analytics} = props;

    return (
        <>
            <Highlight analytics={analytics} standalone={true} highlightTrack={highlightTracks.filter(track => new Date() >= track.publicAt).find(track => track.name === name)}/>
        </>
    );
}

export default Track;