import styled from "styled-components";
import avatarPNG from "../assets/images/avatar.png";
import avatarAVIF from "../assets/images/avatar.avif";
import avatarWEBP from "../assets/images/avatar.webp";

const Avatar = styled.img`
  border-radius: 50%;
  max-width: 250px;
  width: 50vw;
  height: auto;
  display: block;
  object-fit: contain;
  object-position: initial;
  filter: none;
  margin-top: 2vh;

    @media (width: 540px) and (height: 720px), screen {
        width: 40vw;
    }
`;

function AppAvatar(){
    return (
        <picture>
            <source srcSet={avatarAVIF} type={"image/avif"}/>
            <source srcSet={avatarWEBP} type={"image/webp"}/>
            <Avatar src={avatarPNG} alt="workdmark" width={250} height={250}  />
        </picture>



    );
}

export default AppAvatar;