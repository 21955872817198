import Header from "../components/Header";
import Highlight from "../components/Highlight";
import {getLatestRelease} from "../tracks";

function Main(props) {
    const {analytics} = props;

    const latestRelease = getLatestRelease();

    return (
        <>
            <Header analytics={analytics}/>
            <Highlight analytics={analytics} highlightTrack={latestRelease}  limit={3} />
        </>
    );
}

export default Main;