import {isSupported, logEvent} from "firebase/analytics";
import styled from "styled-components";

const AlbumInfoLinkContainer = styled.div`
  padding-bottom: 1vh;
  padding-top: 1vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  flex-grow: 1;
  
  &:last-child{
    border-radius: 0px 0px 10px 0px;
  }
  
  &:not(:last-child){
    border-bottom: 1px solid ${props => props.theme.color.gray.scale200};
  }
  
  &:hover {
    background-color: ${props => props.theme.color.gray.scale125};
  }
`;

const LinkName = styled.div`
  display: flex;
  justify-content: center;
  float: left;
  margin-left: 0.5vw;
  width: 40%;
  max-width: 20vw;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width:1340px){
    margin-left: 2vw;
    max-width: 25vw;
  }
`;

const LinkButton = styled.div`
  display: flex;
  justify-content: center;
  float: right;
  font-size: max(calc(1px + 1vw),14px);
  margin-right: 0.5vw;
  a {
    display: inline-block;
    border: 2px solid ${props => props.theme.color.gray.scale200};
    padding: max(0.5vh, 5px) max(1.5vw,16px);
    background-color: ${props => props.theme.color.gray.scale100};
    color:${props => props.theme.color.brand.tertiary.medium};
    text-decoration: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: ${props => props.theme.fontFamily.brand}, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
    font-size: max(min(calc(8px + 1vw),22px),14px);
  }
  a:visited {
    background-color: ${props => props.theme.color.gray.scale100};
    color:${props => props.theme.color.brand.tertiary.medium};
  }
  a:hover {
    background-color: ${props => props.theme.color.brand.tertiary.medium};
    color: ${props => props.theme.color.gray.scale100};
    border-color: transparent;
    font-weight: ${props => props.theme.fontWeight.bold};
  }
  @media (max-width:1340px){
    margin-right: 2vw;
  }
`;


const eventTags  = {
  "play": {
      gConversionEvent: "AW-965120546/7dEgCIOB3K8YEKKkmswD",
      analyticsEvent: "play_link_clicked"
  },
    "preSave": {
        gConversionEvent: "AW-965120546/7ti2CPfyza4YEKKkmswD",
        analyticsEvent: "pre_save_link_clicked"
    }
};

function AlbumInfoLink(props){
    const {link, track, analytics, event} = props;

    const tags = eventTags[event];

    const logConversion = () => {
        // eslint-disable-next-line no-undef
        gtag('event', 'conversion', {
            'send_to': tags.gConversionEvent
        });
    };

    const pixelViewContentConversion = () => {
        // eslint-disable-next-line no-undef
        fbq('track', 'ViewContent', {
            content_name: 'Spotify',
            content_category: 'Streaming Service',
            content_ids: ['spotify'],
            content_type: 'stream'
        });
    };

    const pixelLeadConversion = () => {
        // eslint-disable-next-line no-undef
        fbq('track', 'Lead', {
            content_name: 'Spotify Pre-Save',
            content_category: 'Streaming Service',
            content_ids: ['spotify-pre-save'],
            content_type: 'pre-save'
        });
    };

    const logPreSave = (service, track) => {
        try{
            if(service === "spotify"){
                if(event==='preSave'){
                    pixelLeadConversion();
                }else if(event === "play"){
                    pixelViewContentConversion();
                }
            }
            logConversion();
            if(!analytics) return;
            isSupported().then(() => {
                logEvent(analytics, tags.analyticsEvent,{service, track  });
            });
        }catch(error){
            console.error(error);
        }

    };

    return (
        <AlbumInfoLinkContainer>
            <LinkName aria-label={link.name}>
                <img src={link.icon} alt={link.name} width={2362} height={709}/>
            </LinkName>
            <LinkButton aria-label={link.label+" on "+link.name}>
                <a href={link.link} target="_blank" rel="noreferrer noopener" onClick={() => {
                    logPreSave( link.key, track.name );
                }}>
                    {link.label}
                </a>
            </LinkButton>
        </AlbumInfoLinkContainer>
    );

}

export default AlbumInfoLink;