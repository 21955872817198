import wordmark from "../assets/icons/wordmark.svg";
import styled from "styled-components";


const FooterContainer = styled.div`
    width: 100%;
    display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: stretch;
  flex-wrap: wrap;
`;

const Wordmark = styled.img`
  filter: ${props => props.theme.filter.white};
  margin-top: 1.5vh;
  width: calc(100px + 15vmin);
  height: auto;
  margin-right: 3vmin;
  margin-bottom: 2vmin;
  float: right;
`;

function Footer() {
    return (
        <FooterContainer>
            <Wordmark src={wordmark} className="App-wordmark" alt="workdmark" width={16} height={16} />
        </FooterContainer>
    );
}

export default Footer;