import AlbumInfoLink from "./AlbumInfoLink";
import styled from "styled-components";
import {AppTitle} from "./Header";

const AppHighlight = styled.div`
  margin-top: 5vh;
  font-family: ${props => props.theme.fontFamily.system}, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: calc(20px + 2vmin);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1;
`;

const AppHighlightTitle = styled.h2`
  font-weight: ${props => props.theme.fontWeight.light};
  font-size: unset;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
`;

const AppHighlightBox = styled.div`
  background-color: ${props => props.theme.color.gray.scale100};
  margin-top: 1vh;
  border-radius: 0px 10px 10px 0px;
  display: inline-flex;
  max-width:  ${props => props.$standalone ? "unset" : "30vw"};

  @media (max-width:480px){
    flex-direction: ${props => props.$standalone ? "column" : ""};
    max-width: 80%;
  }
  
  @media (max-width:1340px){
    min-width: 70%;
    border-radius: 10px 10px 10px 10px;
  }

`;
const AlbumCoverBox = styled.div`
  position: relative;
  overflow: hidden;
  
  
  @media (max-width:1340px){
    display:  ${props => !props.$standalone ? 'none' : ''};
  }
`;
const AlbumCover = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  max-width: 500px;
`;
const AlbumInfoBox = styled.div`
  color:${props => props.theme.color.brand.primary.darkest};
  text-align: left;
  max-width:  ${props => props.$standalone ? "unset" : "45%"};
  display: flex;
  flex-direction: column;
  border-radius: 0px 10px 10px 0px;
  border: 3px solid ${props => props.theme.color.gray.scale125};

  @media (max-width:480px){
    border: unset;
  }
  
  @media (max-width:1340px){
    max-width: unset;
    float: unset;
    width: ${props => props.$standalone ? "unset" : "100%"};
    border-radius: ${props => props.$standalone ? "0px 10px 10px 0px" : "10px 10px 10px 10px"};
  }
`;
const AlbumInfoHeader = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.gray.scale200};
  padding-bottom: 2vh;
  @media (max-width:1340px){
    padding-bottom: 1vh;
  }
`;
const AlbumInfoLinks = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const TrackName = styled.div`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: max(calc(8px + 1vw),22px);
  margin-left: 1vw;
  margin-top: 1vw;
`;

const ArtistName = styled.div`
  font-weight: ${props => props.theme.fontWeight.regular};
  font-size: max(calc(6px + 1vw),16px);
  margin-left: 1vw;
`;


// https://newsroom.spotify.com/media-kit/logo-and-brand-assets/
// https://deezerbrand.com/d/9wmMErzrRuiH/brand-elements
// https://press.aboutamazon.com/logos

function Highlight(props){

    const {analytics, limit, standalone, highlightTrack} = props;

    if(!highlightTrack) return (<></>);

    const isReleased = new Date() >= highlightTrack.releaseDate;

    const event = isReleased ? "play" : "preSave";

    const links = isReleased ? highlightTrack.playLinks : highlightTrack.preSavelinks;

    const title = isReleased ? `Listen to ${highlightTrack.name} now!`  : `Pre-save ${highlightTrack.name} now!`;

    return (
        <AppHighlight>
            {standalone && (
                <AppTitle>
                    {highlightTrack.name}
                </AppTitle>
            )}
            <AppHighlightTitle>
                {title}
            </AppHighlightTitle>

            <AppHighlightBox $standalone={standalone}>
                <AlbumCoverBox $standalone={standalone}>
                    <picture>
                        {highlightTrack.cover.avif && (<source srcSet={highlightTrack.cover.avif} type={"image/avif"}/>)}
                        {highlightTrack.cover.webp && (<source srcSet={highlightTrack.cover.webp} type={"image/webp"}/>)}
                        <AlbumCover src={highlightTrack.cover.jpg || highlightTrack.cover.png } width={500} height={500} property="album-cover" alt={highlightTrack.name+"-album-cover"} />
                    </picture>
                </AlbumCoverBox>
                <AlbumInfoBox $standalone={standalone}>
                    <AlbumInfoHeader>
                        <TrackName property="track-name">
                            {highlightTrack.name}
                        </TrackName>
                        <ArtistName property="artist-name" >
                            {highlightTrack.artist}
                        </ArtistName>
                    </AlbumInfoHeader>

                    <AlbumInfoLinks>
                        {links.filter(link => link.link).slice(0,limit).map(link => (
                            <AlbumInfoLink key={link.key} analytics={analytics} track={highlightTrack} link={link} event={event}/>
                        ))}
                    </AlbumInfoLinks>
                </AlbumInfoBox>
            </AppHighlightBox>
        </AppHighlight>
    );

}

Highlight.defaultProps = {
    limit: undefined,
    standalone: false,
}

export default Highlight;